.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-sign-in {
  margin-left: auto;
}

.active-link {
  border-bottom: 2px solid #ffcc00;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link-custom.active-link {
  border-bottom: 5px solid yellow;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-weight: bold;
  line-height: 2;  
  display: inline-block;
  padding-bottom: 3px;
}

/* Media Query untuk Mobile */
@media (max-width: 768px) {
  .navbar-collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000060;
  }

  .nav-link-custom {
    text-align: center;
    width: 100%;
    margin: 10px 0; /* Beri jarak antar elemen */
  }

  .navbar-toggler {
    margin-left: auto; /* Buat toggle menu tetap berada di kanan */
  }

  .navbar-sign-in {
    margin-left: 0;
    text-align: center;
  }

}

.nav-link-custom {
  margin: 0;
  position: relative;
  display: inline-flex;
  font-size: 18px;
  font-weight: bold;
  color: white !important;
}

.nav-link-custom:hover {
  border-bottom: 5px solid yellow;
}

.nav-link-custom:hover .navbar-dropdown {
  display: block; /* Tampilkan dropdown saat dihover */
}

#profile-nav-dropdown .dropdown-toggle {
  color: #000050 !important;
}

#profile-nav-dropdown .dropdown-toggle::after {
  color: #000050 !important;
}
.navbar-dropdown {
  background-color: #000050;
  z-index: 1050;
  display: none;
  position: absolute; /* Tetapkan posisi absolut */
  top: 0; /* Tepat di bawah elemen "Tentang" */
  left: 0;
}
.navbar-dropdown .dropdown-menu.show {
  display: block;
  border-radius: 0px;
}
.navbar-dropdown .dropdown-item {
  font-size: 18px;
  background-color: #000050;
  color: white;
}

.navbar-dropdown .dropdown-menu {
  background-color: #000060 !important;
  border: none;
}


.info-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.info-box {
  flex: 1 1 calc(25% - 10px);
  margin: 5px;
  padding: 20px;
  color: white;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.info-box h3 {
  margin: 0;
  font-size: 1.5rem;
}

.info-box p {
  margin: 10px 0 0 0;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .info-box {
    flex: 1 1 calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .info-box {
    flex: 1 1 100%;
  }
}



