.leaflet-container {
    margin: auto;
    margin-top: 5%;
    width: 100%;
    height: 60vh;
  }

  .navbar-collapse {
    background-color: #fff; /* Ganti dengan warna latar belakang yang Anda inginkan */
  }
  
  .nav {
    background-color: #190482;
  }

  .img-bg {
    background-image: url('assets/bg1.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 90.7vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: 700px){
    .slogan {
      font-size: small;
    }
  }

  .info-boxes {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .info-box {
    width: 20%;
    padding: 20px;
    color: white;
    text-align: center;
    border-radius: 10px;
  }
  
  .info-box h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .info-box p {
    margin: 10px 0 0 0;
    font-size: 1.2rem;
  }
  
  .image-container {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .slideshow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  
  .slide-image {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust the opacity as needed */
    z-index: 1;
  }
  
  .text-content {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 5px;
    margin: 100px;
    animation-name: highlight;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes highlight {
    0% {
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;
    }
    100% {
      background-color: rgba(46, 46, 44, 0.5); /* Highlight color */
      color: #ffffff;
    }
  }
  
  .large-text {
    font-size: 25px;
    width: 70%;
    margin: 0 auto;
  }
  
  .small-text {
    font-size: 16px; /* Adjust font size for mobile */
    width: 90%;
    margin: 0 auto;
  }
  
  .button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #91919179;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    z-index: 2;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .schedule-container {
    display: flex;
    flex-direction: column;
    width: 200px; /* Atur lebar sesuai kebutuhan */
    margin: auto; /* Centering the container */
    margin-left: 5px;
  }
  
  .schedule-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  
  .day {
    flex: 1;
    text-align: left;
  }
  
  .time {
    flex: 1;
    text-align: left;
  }
  